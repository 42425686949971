import Clients from '@@components/sections/clients/Clients';
import Community from '@@components/sections/community/Community';
import Faq from '@@components/sections/faq/Faq';
import Header from '@@components/sections/header/Header';
import Roadmap from '@@components/sections/roadmap/Roadmap';
import Services from '@@components/sections/services/Services';

export const SocialLinks = [
  {
    name: 'Telegram',
    icon: '/imgs/social/telegram.svg',
    href: 'https://t.me/pipandchart',
  },
  {
    name: 'TikTok',
    icon: '/imgs/social/tiktok.svg',
    href: 'https://www.tiktok.com/@pip.and.chart',
  },
  {
    name: 'Facebook',
    icon: '/imgs/social/facebook.svg',
    href: '#facebook_link_missing',
  },
  {
    name: 'Instagram',
    icon: '/imgs/social/instagram.svg',
    href: 'https://www.instagram.com/pipandchart/',
  },
];
export const PageContent = [
  // { href: 'home', component: Header },
  { title: 'Header', href: 'header', component: Header },
  { title: 'Roadmap', href: 'roadmap', component: Roadmap },
  { title: 'Take a look into our  group.', href: 'clients', component: Clients },
  { title: 'Services', href: 'services', component: Services },
  { title: 'Community', href: 'community', component: Community },
  { title: 'FAQ', href: 'FAQ', component: Faq },
];

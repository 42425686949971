import Section from '@@components/Section';
import TelegramLink from '@@components/TelegramLink';
import { title } from '@@configs/page/meta.config';
import { SocialLinks } from '@@configs/page/site.config';
import React from 'react';
import Link from 'next/link';

function Header() {
  return (
    <Section id="header" title={title} className="grid gap-3 text-center">
      <h3>Trade and grow with us.</h3>
      <TelegramLink className="mt-10" />
    </Section>
  );
}

export default Header;

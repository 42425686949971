import { Icon } from 'next/dist/lib/metadata/types/metadata-types';
import type { Metadata, Viewport } from 'next/types';

/**
 * Constants
 */
const imageMultiplier = 1;
const urlMap = {
  preview: 'https://dev.pipandchart.com',
  production: 'https://pipandchart.com',
  development: `http://localhost:${process.env.PORT || 3000}`,
};
export const url = urlMap[process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'development'];
export const icon: Icon[] = [
  { url: '/logo96.png', sizes: '96x96', type: 'image/png' },
  { url: '/logo256.png', sizes: '256x256', type: 'image/png' },
  { url: '/logo512.png', sizes: '512x512', type: 'image/png' },
];
export const title = 'Pip & Chart™️';
export const description = 'Pip & Chart™️ SIGNALS: GOLD, EURUSD, GBPUSD';

/**
 * Config: Metadata
 */
export const metadata = {
  metadataBase: new URL(url),
  title: {
    default: title,
    template: `%s - ${title}`,
  },
  description,
  applicationName: title,
  creator: 'Márk Szőke',
  category: 'Forex trading',
  manifest: '/manifest.json',
  icons: { icon, shortcut: icon, apple: icon },
  // verification: { google: 'wch9Z7oq6MY75zhKQqXBAe74Ohp9jMsvw3oe8vVOC4I' },
  keywords: ['forex', 'exchange', 'money', 'dollar', 'signal', 'gold', 'chart'],
  robots: { index: true, follow: true, nocache: true, googleBot: { index: true, follow: true } },
  alternates: {
    canonical: '/', //   // canonical: '/' + LocaleList[0].value,
    //   languages: LocaleList.reduce((obj, { value, hreflang }) => ({ ...obj, [hreflang]: '/' + value }), {
    //     [LocaleList[0].hreflang]: '/',
    //     'x-default': '/',
    //   } as object),
  },
  openGraph: {
    url,
    title,
    description,
    siteName: title,
    images: [{ alt: title, url: '/imgs/og.png', type: 'website', width: 1530 * imageMultiplier, height: 872 * imageMultiplier }],
    type: 'website',
    locale: 'hu-HU',
    countryName: 'Hungary',
    // phoneNumbers: '+36308802363',
    // emails: 'info@hashtag-debrecen.hu',
  },
  referrer: 'origin-when-cross-origin',
} satisfies Metadata;

export const viewport: Viewport = { themeColor: 'dark', colorScheme: 'dark', minimumScale: 1, initialScale: 1, width: 'device-width' };

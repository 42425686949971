'use client';

import Section from '@@components/Section';
import { clx_ } from '@@libs/clx';
import { motion } from 'framer-motion';
import React from 'react';
// import reactStringReplace from 'react-string-replace';
import css from './Roadmap.module.scss';

const clx = clx_(css);

export default function Roadmap() {
  return (
    <Section
      id="roadmap" //
      title="Why become a member?"
      className="grid justify-center gap-4"
      props={{ title: { className: '!text-4xl' } }}
    >
      <motion.div
        className={clx('wrap')}
        whileInView="show"
        initial="hide"
        viewport={{ margin: '2000px 0px -200px 0px' }}
        variants={{
          hide: {
            opacity: 0,
            x: -25, //i % 2 == 0 ? -25 : 25,
          },
          show: {
            opacity: 1,
            x: 0,
          },
        }}
      >
        {/* <span className={clx('badge')}>Why become a member?</span> */}
        <ul className={clx('item')}>
          <li>Receive daily alerts via Telegram</li>
          <li>User-friendly. No previous experience needed</li>
          <li>Follow proven strategies over the years</li>
          <li>
            Our pairs we trade:
            <br />
            <b>EURUSD</b>
            <b>GBPUSD</b>
            <b>XAUUSD</b>
            <b>US30</b>
            <b>US100</b>
          </li>
        </ul>
      </motion.div>
      {/* {roadMap.map(({ text, title, images }, i) => (
        <motion.div
          key={i}
          className={clx('wrap')}
          whileInView="show"
          initial="hide"
          viewport={{ margin: '2000px 0px -200px 0px' }}
          variants={{
            hide: {
              opacity: 0,
              x: i % 2 == 0 ? -25 : 25,
            },
            show: {
              opacity: 1,
              x: 0,
            },
          }}
        >
          <span className={clx('badge')}>{title}</span>
          <span className={clx('item')}>
            {text}
            {reactStringReplace(text, '{site}', (m, i) => (
              <Link href={listingSite.href} key={i} className="px-2 py-1 uppercase rounded bg-black/30" target="_blank" rel="noopener">
                {listingSite.name}
              </Link>
            ))}
            {!!images?.length && (
              <div className="mt-4 flex flex-col gap-4">{images?.map((img) => <img key={img} src={img} className="m-auto max-w-80 rounded-md" />)}</div>
            )}
          </span>
        </motion.div>
      ))} */}
    </Section>
  );
}

const roadMap = [
  // {
  //   title: 'Welcome to PIPANDCHART signal services',
  //   text: `We analyse the market and share all trades taken by us
  // 	Join our FREE telegram channel.
  // 	(ide egy kis telegram kattintós link)

  // 	`,
  // },
  // {
  //   title: 'Why become a member?',
  //   text: `Receive daily alerts via Telegram
  //   User-friendly. No previous experience needed
  //   Follow proven strategies over the years
  //   Our pairs we trade:
  //   EURUSD,GBPUSD,XAUUSD,US30,US100`,
  // },
  {
    title: 'We beleive everyone deserve to try out a service before purchase.',
    text: `We have our TRYOUT package for 3 days ! ONLY 3.99 $`,
    // images: ['/imgs/logo_255.png'],
    images: [],
  },
];

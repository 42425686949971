'use client';

import { SocialLinks } from '@@configs/page/site.config';
import clx from '@@libs/clx';
import React from 'react';
import Link from 'next/link';

function TelegramLink({ className, ...props }: any) {
  return (
    <Link
      href={SocialLinks[0].href}
      className={clx(
        'mx-auto inline-block rounded-md border-2 border-theme-blue bg-theme-blue/50 p-2 text-base transition-all hover:border-theme-green',
        'shadow-[0_0_2rem_0.1rem_var(--tw-shadow-color)] shadow-white hover:animate-zoom hover:bg-theme-green/50 hover:shadow-theme-green',
        className
      )}
    >
      Join our Free Telegram channel.
    </Link>
  );
}

export default TelegramLink;

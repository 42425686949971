'use client';

import Section, { BasicSectionProps } from '@@components/Section';
import TelegramLink from '@@components/TelegramLink';
import { clx_ } from '@@libs/clx';
import { ClassValue } from 'clsx';
import { motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import css from '@@components/sections/roadmap/Roadmap.module.scss';

const clx = clx_(css);

type CommunityProps = BasicSectionProps & {
  //
};
function Services({ title, href }: CommunityProps) {
  return (
    <Section
      id={href} //
      title={title}
      className="flex flex-wrap justify-center gap-4"
      props={{ title: { className: '!text-4xl' } }}
    >
      <motion.div
        className={clx('services-wrap')}
        whileInView="show"
        initial="hide"
        viewport={{ margin: '2000px 0px -200px 0px' }}
        variants={{
          hide: {
            opacity: 0,
            x: -25, //i % 2 == 0 ? -25 : 25,
          },
          show: {
            opacity: 1,
            x: 0,
          },
        }}
      >
        <ServiceItem href="#" title="Tryout package" buttonTitle="6.99 $" className="shadow-[0_0_4rem_0.2rem_var(--tw-shadow-color)] shadow-white">
          <p>Everyone deserve to try a service out before purchase</p>
          <br />
          <p className="lg:mb-auto">3 days membership</p>
          <p className="lg:-mb-12">(This service can be bought only once per customer)</p>
        </ServiceItem>

        <ServiceItem href="#" title="Monthly membership" buttonTitle="49.99 $">
          <p>-signals everyday</p>
          <p>-high risk reward</p>
          <p>-weekly reports</p>
          <p>-weekly webinars</p>
        </ServiceItem>

        <ServiceItem href="#" title="Lifetime membership" buttonTitle="499.99$">
          <p>-signals everyday</p>
          <p>-high risk reward</p>
          <p>-weekly reports</p>
          <p>-weekly webinars</p>

          <br />
          <p>Save 20% at the first year and 100% at the second</p>
        </ServiceItem>
      </motion.div>
      {/* {roadMap.map(({ text, title, images }, i) => (
      <motion.div
        key={i}
        className={clx('wrap')}
        whileInView="show"
        initial="hide"
        viewport={{ margin: '2000px 0px -200px 0px' }}
        variants={{
          hide: {
            opacity: 0,
            x: i % 2 == 0 ? -25 : 25,
          },
          show: {
            opacity: 1,
            x: 0,
          },
        }}
      >
        <span className={clx('badge')}>{title}</span>
        <span className={clx('item')}>
          {text}
          {reactStringReplace(text, '{site}', (m, i) => (
            <Link href={listingSite.href} key={i} className="px-2 py-1 uppercase rounded bg-black/30" target="_blank" rel="noopener">
              {listingSite.name}
            </Link>
          ))}
          {!!images?.length && (
            <div className="mt-4 flex flex-col gap-4">{images?.map((img) => <img key={img} src={img} className="m-auto max-w-80 rounded-md" />)}</div>
          )}
        </span>
      </motion.div>
    ))} */}
      <TelegramLink className="mt-10 flex-fill" />
    </Section>
  );
}

export default Services;

type ServiceItemProps = PropsWithChildren<{
  href: Url;
  title: string;
  buttonTitle: string;
  className?: ClassValue;
}>;
const ServiceItem = ({ href, title, buttonTitle, className, children, ...props }: ServiceItemProps) => {
  return (
    <article className={clx('wrap', '!w-auto', className)}>
      <span className={clx('badge')}>{title}</span>
      <div className={clx('item', 'flex flex-col gap-2')}>
        {/* <h5>{title}</h5> */}
        {children}
        <Link href={href} className="ml-auto mt-auto inline-block rounded-inherit border border-white bg-black/25 px-2 py-1">
          {buttonTitle}
        </Link>
      </div>
    </article>
  );
};

const items = [
  //
  { title: 'Tryout package', price: 6.99 },
];
